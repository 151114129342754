<template>
  <div class="contents">
    <!-- 스트랩핑 관리_결과조회 -->
    <div class="card shadow">
      <div class="card-body">
        <!-- 검색영역  -->
        <div class="credit-table-block pl20 pt20 pb20 mb20">
          <!-- 1 row -->
          <div class="tx_lt pb5 pt5">
            <!-- 자동수집 group-->
            <base-select-box
                :class="selectBoxClass1"
                :items="selectBoxItems1"
                @toParent:selectValue="val => changeSelectBoxItems1(val)" />
            <!-- 자동수집 내역명 -->
            <base-select-box
                :class="selectBoxClass4"
                :items="selectBoxItems2"
                @toParent:selectValue="val => scrapName = val" />

            <!-- 카드/은행사 -->
            <base-select-box
                :class="selectBoxClass2"
                :items="cardBankCorpList"
                v-if="scrapGroup == 'CARD' || scrapGroup == 'BANK'"
                @toParent:selectValue="val => scrapSiteKey = val" />

            <!-- 성공/실패 -->
            <base-select-box
                :class="selectBoxClass2"
                :items="selectBoxItems3"
                @toParent:selectValue="val => changeSelectedOpt1(val)" />
          </div>
          <!-- 2 row -->
          <div class="tx_lt pb5 pt5">
            <!-- 조회일자 기준 -->
            <base-calendar-in-search-row
                :isDateGubun="isDateGubun"
                @toParent:selectDateGubun="val => dateGubun = val"
                @toParent:setFromDate="val => fromDate = val"
                @toParent:setToDate="val => toDate = val" >

              <button slot="slot_searchBtn" type="button" class="btn btn-darkgrey ml15 mr20 w120" @click="getScrapHistory(true)">조회</button>
            </base-calendar-in-search-row>
          </div>
        </div>
        <!-- //검색영역  -->
        <!-- 세무대리인 추가 검색조건 -->
        <div class="tx_rt pb10" v-if="scrapGroup=='HOMETAX' && isCompanyOrAgentType == 'AGENT'">
          <!-- 사업자번호 -->
          <label class="tx_rt ml5 mr10 bold"><i class="fas fa-caret-right fs14 mr5"></i>사업자번호</label>
          <input class="w150" type="text" placeholder="사업자번호 입력" v-model="enteredCorpRegNo" />
          <!-- 생년월일 -->
          <label class="tx_rt ml5 mr10 bold"><i class="fas fa-caret-right fs14 ml10 mr5"></i>생년월일</label>
          <input class="w150" type="text" placeholder="생년월일 입력" v-model="enteredBirthday" />
          <!-- 상호 -->
          <label class="tx_rt ml5 mr10 bold"><i class="fas fa-caret-right fs14 ml10 mr5"></i>상호</label>
          <input class="w130" type="text" placeholder="상호 입력" v-model="enteredCorpName" />
          <!-- 대표자 -->
          <label class="tx_rt ml5 mr10 bold"><i class="fas fa-caret-right fs14 ml10 mr5"></i>대표자</label>
          <input class="w130" type="text" placeholder="대표자 입력" v-model="enteredReprName" />
          <button type="button" class="btn btn-default ml10 mr5 mb5 w80" @click="getScrapHistory(true)">조회</button>
        </div>
        <!-- //세무대리인 추가 검색조건 -->

        <table class="table01" v-if="scrapGroup != ''">
          <caption>결과조회 표</caption>
          <colgroup v-if="scrapGroup != 'HOMETAX'">
            <col v-for="(item, index) in tableItems.heads" :key="index" :style="item.style">
          </colgroup>
          <colgroup v-if="scrapGroup == 'HOMETAX' && isCompanyOrAgentType == 'COMPANY'">
            <col v-for="(item, index) in tableItems.user_heads" :key="index" :style="item.style">
          </colgroup>
          <colgroup v-if="scrapGroup == 'HOMETAX' && isCompanyOrAgentType == 'AGENT'">
            <col v-for="(item, index) in tableItems.agent_heads" :key="index" :style="item.style">
          </colgroup>
          <thead>
          <tr v-if="scrapGroup != 'HOMETAX'">
            <th v-for="(item, index) in tableItems.heads" :key="index" class="tx_cnt" v-html="item.col">{{ item.col }}</th>
          </tr>
          <tr v-if="scrapGroup == 'HOMETAX' && isCompanyOrAgentType == 'COMPANY'">
            <th v-for="(item, index) in tableItems.user_heads" :key="index" class="tx_cnt" v-html="item.col">{{ item.col }}</th>
          </tr>
          <tr v-if="scrapGroup == 'HOMETAX' && isCompanyOrAgentType == 'AGENT'">
            <th v-for="(item, index) in tableItems.agent_heads" :key="index" class="tx_cnt" v-html="item.col">{{ item.col }}</th>
          </tr>
          </thead>
          <!-- 홈택스 그 외 스크랩핑 -->
          <tbody v-if="scrapGroup == 'HOMETAX' || scrapGroup == 'CREDIT' || scrapGroup == 'NHIS' || scrapGroup == 'NPS' || scrapGroup == 'HIRA' || scrapGroup == 'MEDI' && tableItems.items.length != 0">
          <tr v-for="(item, index) in tableItems.items" :key="index">
            <td class="tx_cnt">{{ item.mode.displayName }}</td>
            <td class="tx_cnt">{{ item.scrapName }}</td>
            <td class="tx_cnt" v-if="isHometaxFromToYmdScrap(item.scrapId) && isCompanyOrAgentType == 'AGENT'">{{ item.inqStartDate }} ~ {{ item.inqEndDate }}</td>
            <td class="tx_cnt" v-if="isHometaxUnnecessaryScrap(item.scrapId) && isCompanyOrAgentType == 'AGENT'"> - </td>
            <td class="tx_cnt" v-if="isHometaxYearScrap(item.scrapId) && isCompanyOrAgentType == 'AGENT'">{{ item.inqStartYear }}년도</td>
            <td class="tx_cnt" v-if="isMediYearScrap(item.scrapId) && isCompanyOrAgentType == 'COMPANY'">{{ item.inqStartYear }}년도</td>
            <td class="tx_cnt" v-if="isHometaxYearQuarterScrap(item.scrapId) && isCompanyOrAgentType == 'AGENT'">{{ item.inqStartYear }}년도 {{ item.inqStartSubData }}분기</td>
			  <td class="tx_cnt" v-if="isHometaxVatQuarterScrap(item.scrapId) && isCompanyOrAgentType == 'AGENT'">{{ item.inqStartYear }}년도 {{ item.strVatQuarter }}</td>
            <!-- 여기가 썩 마음에 들지 않는데. COMPANY이면서 default from_to 스크랩 구분 조건.. 추후 보강 -->
            <td class="tx_cnt" v-if="!isMediYearScrap(item.scrapId) && isCompanyOrAgentType == 'COMPANY'">{{ item.inqStartDate }} ~ {{ item.inqEndDate }}</td>
            <td class="tx_cnt">{{ item.sendStartDate  }}</td>
            <td class="tx_cnt" v-if="isCompanyOrAgentType == 'AGENT'">{{ item.corpRegNo  }}</td>
            <td class="tx_cnt" v-if="isCompanyOrAgentType == 'AGENT'">{{ item.resNo }}</td>
            <td class="tx_cnt" v-if="isCompanyOrAgentType == 'AGENT'">{{ item.corpName }}</td>
            <td class="tx_cnt" v-if="isCompanyOrAgentType == 'AGENT'">{{ item.reprName }}</td>
            <td class="tx_cnt">{{ item.count }}</td>
            <td class="tx_cnt txt-primary" v-if="item.status.code == 'SUCCESS'">정상</td>
            <td class="tx_cnt txt-danger" v-if="item.status.code != 'SUCCESS'">실패</td>
			<td class="tx_lt ellipsis"><span v-bind:title="item.resultMessage">{{ item.resultMessage }}</span></td>
            <td class="tx_cnt"><button class="btn btn-default btn-sm" @click.prevent="runScrappingManual(index)">수동실행</button></td>
          </tr>
          </tbody>
          <tbody v-if="(scrapGroup == 'HOMETAX' || scrapGroup == 'CREDIT' || scrapGroup == 'NHIS' || scrapGroup == 'NPS' || scrapGroup == 'HIRA' || scrapGroup == 'MEDI' ) && tableItems.items.length == 0">
            <tr v-if="isCompanyOrAgentType == 'COMPANY'">
              <td class="tx_cnt" colspan="8">조회된 데이터가 없습니다.</td>
            </tr>
            <tr v-if="isCompanyOrAgentType == 'AGENT'">
              <td class="tx_cnt" colspan="12">조회된 데이터가 없습니다.</td>
            </tr>
          </tbody>
          <!-- 신용카드/은행 스크랩핑 -->
          <tbody v-else-if="scrapGroup == 'CARD' || scrapGroup == 'BANK' && tableItems.items.length != 0">
          <tr v-for="(item, index) in tableItems.items" :key="index">
            <td class="tx_cnt">{{ item.mode.displayName }}</td>
            <td class="tx_cnt">{{ item.bizKind.displayName }}</td>
            <td class="tx_cnt">{{ item.scrapName }}</td>
            <td class="tx_cnt">
              <span class="displayblock pt5">{{ item.siteName }}</span>
              <span class="displayblock pb5">{{ item.financeNo }}</span>
            </td>
            <td class="tx_cnt">{{ item.inqStartDate }} ~ {{ item.inqEndDate }}</td>
            <td class="tx_cnt">{{ item.sendStartDate  }}</td>
            <td class="tx_cnt">{{ item.count }}</td>
            <td class="tx_cnt txt-primary" v-if="item.status.code == 'SUCCESS'">정상</td>
            <td class="tx_cnt txt-danger" v-if="item.status.code != 'SUCCESS'">실패</td>
			<td class="tx_lt ellipsis"><span v-bind:title="item.resultMessage">{{ item.resultMessage }}</span></td>
            <td class="tx_cnt"><button class="btn btn-default btn-sm" @click.prevent="runScrappingManual(index)">수동실행</button></td>
          </tr>
          </tbody>
          <tbody v-if="(scrapGroup == 'CARD' || scrapGroup == 'BANK') && tableItems.items.length == 0">
            <tr>
              <td class="tx_cnt" colspan="10">조회된 데이터가 없습니다.</td>
            </tr>
          </tbody>
        </table>
        <table v-else-if="scrapGroup == ''" style="height:250px" >
          <tbody>
          <tr>
            <td class="tx_cnt"><span>조회할 자료를 선택해주세요.</span></td>
          </tr>
          </tbody>
        </table>

        <!-- Paging -->
        <base-pagination
            :prefix="'/scrap/historyMng'"
            :pTotalListItemCount="totalListCnt"
            :pageInit="pageInit"
            @toParent:changePage="(val1, val2) => currPage = val2" />
      </div>
    </div>

    <!-- 스크랩핑 결과 상세팝업 -->
    <base-scrap-manual-details-popup
        :popupTitle="detailsPopupTitle"
        :visible="popupVisible"
        :groupKey="popupHstGroupKey"
        :fromDate="popupFromDate"
        :toDate="popupToDate"
        :year="popupYear"
        :yearQuarter="popupYearQuarter"
        :vatQuarter="popupVatQuarter"
        :scrapPeriodStandard="scrapPeriodStandard"
        :needSearchAndPaging="needSearchAndPaging"
        @toParent:visible="val => popupVisible = val"/>
    <!-- //스크랩핑 결과 상세팝업 -->
    <!-- //스트랩핑 관리_결과조회 -->
  </div>
</template>
<script>
import {mapState, mapMutations} from "vuex";

export default {
  name: 'PageScrapingHistory',

  data: () => ({
    // Variables
    jsonHeader: {
      headers: {
        'Content-Type': 'application/json;'
      }
    },

    //검색영역 관련
    searchParams: {},
    scrapGroup: '',
    scrapId: '',
    scrapName: '',
    scrapSiteKey: '',
    scrapStatus: '',
    dateGubun: '',
    isDateGubun: true,
    fromDate: '',
    toDate: '',
    enteredCorpRegNo: '',
    enteredBirthday: '',
    enteredCorpName: '',
    enteredReprName: '',

    //페이지 관련
    currPage: 1,
    pageLimit: 10,
    pageInit: false, //현재 페이지 초기화 여부 체크용
    totalListCnt: 0, //조회내역 총 갯수. 페이지수 계산용으로 필요

    //그리드 관련
    tableItems: '',
    hometaxTableItems: {
      user_heads: [
        {col: '실행구분', style: 'width:50px',}, {col: '자동자료명', style: 'width:100px',},
        {col: '조회일자', style: 'width:120px',}, {col: '실행일시', style: 'width:100px',},
        {col: '조회건수', style: 'width:50px',}, {col: '결과', style: 'width:50px',}, {col: '비고', style: '',},
        {col: '실행', style: 'width:80px',},
      ],
      agent_heads: [
        {col: '실행구분', style: 'width:50px;',}, {col: '자동자료명', style: 'width:100px;',},
        {col: '조회일자', style: 'width:120px;',}, {col: '실행일시', style: 'width:100px;',}, //{col: '사업체구분', style: '5%'},
        {col: '사업자번호', style: 'width:120px;'}, {col: '주민번호', style: 'width:120px;'},
        {col: '상호', style: 'width:100px;'}, {col: '대표자', style: 'width:70px;'},
        {col: '조회건수', style: 'width:50px;',}, {col: '결과', style: 'width:50px;',}, {col: '비고', style: '',},
        {col: '실행', style: 'width:80px;',},
      ],
      items: [],
    },
    cardTableItems: {
      heads: [
        {col: '실행구분', style: 'width:50px',}, {col: '대상', style: 'width:50px',}, {col: '자동자료명', style: 'width:100px',},
        {col: '금융기관<br/>카드번호', style: 'width:150px',},
        {col: '조회일자', style: 'width:120px',}, {col: '실행일시', style: 'width:100px',},
        {col: '조회건수', style: 'width:80px',}, {col: '결과', style: 'width:50px',}, {col: '비고', style: '',},
        {col: '실행', style: 'width:80px',},
      ],
      items: [],
    },
    bankTableItems: {
      heads: [
        {col: '실행구분', style: 'width:50px',}, {col: '대상', style: 'width:50px',}, {col: '자동자료명', style: 'width:100px',},
        {col: '금융기관<br/>계좌번호', style: 'width:150px',},
        {col: '조회일자', style: 'width:120px',}, {col: '실행일시', style: 'width:100px',},
        {col: '조회건수', style: 'width:80px',}, {col: '결과', style: 'width:50px',}, {col: '비고', style: '',},
        {col: '실행', style: 'width:80px',},
      ],
      items: [],
    },
    creditTableItems: {
      heads: [
        {col: '실행구분', style: 'width:50px',}, {col: '자동자료명', style: 'width:100px',},
        {col: '조회일자', style: 'width:120px',}, {col: '실행일시', style: 'width:100px',},
        {col: '조회건수', style: 'width:80px',}, {col: '결과', style: 'width:50px',}, {col: '비고', style: '',},
        {col: '실행', style: 'width:80px',},
      ],
      items: [],
    },
    nhisTableItems: {
      heads: [
        {col: '실행구분', style: 'width:50px',}, {col: '자동자료명', style: 'width:100px',},
        {col: '조회일자', style: 'width:120px',}, {col: '실행일시', style: 'width:100px',},
        {col: '조회건수', style: 'width:80px',}, {col: '결과', style: 'width:50px',}, {col: '비고', style: '',},
        {col: '실행', style: 'width:80px',},
      ],
      items: [],
    },
    npsTableItems: {
      heads: [
        {col: '실행구분', style: 'width:50px',}, {col: '자동자료명', style: 'width:100px',},
        {col: '조회일자', style: 'width:120px',}, {col: '실행일시', style: 'width:100px',},
        {col: '조회건수', style: 'width:80px',}, {col: '결과', style: 'width:50px',}, {col: '비고', style: '',},
        {col: '실행', style: 'width:80px',},
      ],
      items: [],
    },
    hiraTableItems: {
      heads: [
        {col: '실행구분', style: 'width:50px',}, {col: '자동자료명', style: 'width:100px',},
        {col: '조회일자', style: 'width:120px',}, {col: '실행일시', style: 'width:100px',},
        {col: '조회건수', style: 'width:80px',}, {col: '결과', style: 'width:50px',}, {col: '비고', style: '',},
        {col: '실행', style: 'width:80px',},
      ],
      items: [],
    },


    // Components attrs
    selectBoxClass1: 'form-control pl5 form-control-inline w150 mr5',
    selectBoxClass2: 'form-control form-control-inline w90 mr5',
    selectBoxClass3: 'form-control pl5 form-control-inline w125 mr5',
    selectBoxClass4: 'form-control pl5 form-control-inline w200 mr5',
    // selectBoxClass5: 'form-control pl5 form-control-inline w80 mr5',

    selectBoxItems1: [
      {key: '', value: '선택',},
    ],
    selectBoxItems2: [
      {key: '', value: '전체'},
    ],
    selectBoxItems3: [
      {key: '', value: '전체', selected: true},
      {key: 'SUCCESS', value: '정상', selected: false},
      {key: 'FAIL', value: '실패', selected: false},
    ],
    cardBankCorpList: [
      {key: '', value: '전체'},
    ],

    //수동실행 상세 팝업 관련
    detailsPopupTitle: '',
    popupVisible: false,
    popupHstGroupKey: '',
    popupFromDate: '',
    popupToDate: '',
    popupYear: '',
    popupYearQuarter: '',
    popupVatQuarter: '',
    popupScrapPeriodStandard: '',
    needSearchAndPaging: false,
  }),
  methods: {
    //========== 검색영역 메서드 1. selectBox 초기화 및 검색영역 param 세팅 ==========
    changeSelectBoxItems1(val) {
      //  검색영역
      this.scrapGroup = val;

      // selectBox 초기화
      this.selectBoxItems2 = [
        {key: '', value: '전체'}
      ];
      this.cardBankCorpList = [
        {key: '', value: '전체'},
      ];
      this.selectBoxItems3 = [
        {key: '', value: '전체', selected: true},
        {key: 'SUCCESS', value: '정상', selected: false},
        {key: 'FAIL', value: '실패', selected: false},
      ];
      // this.changeSelectedOpt2('');
      this.enteredCorpRegNo = '';
      this.enteredBirthday = '';
      this.enteredCorpName = '';
      this.enteredReprName = '';

      // 그리드 초기화
      if (this.tableItems.items != null) {
        this.tableItems.items = [];

        this.pageInit = true;	//현재 페이지 초기화
        this.totalListCnt = 0;	//페이지수 초기화
      }

    },

    //========== 검색영역 메서드 2. 스크랩핑 그룹 목록  ==========
    getScrapGroup() {
      this.$cAxios.post("/api/scrap/history/getScrapGroupList")
          .then((response) => {
            if (response.data.length > 0) {
              // selectBox1 세팅
              for (var i = 0; i < response.data.length; i++) {
                this.obj = {};
                this.obj.key = response.data[i].id;
                this.obj.value = response.data[i].name;
                this.selectBoxItems1.push(this.obj);
              }
            }
          }).catch((error) => {
            console.log(error.response.data.message);
          })
    },

    //========== 검색영역 메서드 3. 스크랩핑 내역 목록  ==========
    getScrap(scrapGroup) {

      this.$cAxios.post("/api/scrap/history/getScrapList", "scrapGroup=" + scrapGroup)
          .then((response) => {

            if (response.data.length > 0) {
              // selectBox2 세팅
              for (var i = 0; i < response.data.length; i++) {
                this.obj = {};
                this.obj.key = response.data[i].id;
                this.obj.value = response.data[i].name;

                this.selectBoxItems2.push(this.obj);

              }
              // cardBankCorpList 세팅 메서드 호출 (신용카드 은행계좌)
              if (this.selectBoxItems2.length > 1 && (scrapGroup == 'CARD' || scrapGroup == 'BANK')) {
                this.getScrapSite(scrapGroup);
              }
            }
          }).catch((error) => {
            console.log(error.response.data.message);
          })
    },

    //========== 검색영역 메서드 4. 신용카드사/은행사 목록 ==========
    getScrapSite(scrapGroup) {
      // cardBankCorpList 세팅
      this.$cAxios.post("/api/scrap/history/getScrapSiteList", "scrapGroup=" + scrapGroup)
          .then(response => {

            if (response.data.length > 0) {
              for (var i = 0; i < response.data.length; i++) {
                this.obj = {};
                this.obj.key = response.data[i].idx;
                this.obj.value = response.data[i].name;

                this.cardBankCorpList.push(this.obj);
              }
            }
          }).catch((error) => {
            console.log(error.response.data.message);
          })
    },

    //========== 검색영역 메서드 5. 고정 옵션 selectBox selected값 변경(초기화위해서) ==========
    //	성공/실패 selected값 변경
    changeSelectedOpt1(val) {

      this.scrapStatus = val;

      for (var i = 0; i < this.selectBoxItems3.length; i++) {
        if (this.selectBoxItems3[i].key == val) {
          this.selectBoxItems3[i].selected = true;
        } else {
          this.selectBoxItems3[i].selected = false;
        }
      }
    },

    //========== 그리드 조회 1. ==========
    getScrapHistory(pageReloadYn) {

      // 검색 조건 재적용해서 새로 조회할 경우
      if (pageReloadYn) {
        this.pageInit = true;	//현재 페이지 초기화
        this.totalListCnt = 0;	//페이지수 초기화
        this.currPage = 1;    //이 스크립트용 현재페이지값 초기화 (서버 보내줘야함)
      }

      if (this.scrapGroup == '') {
        alert('조회할 자료를 선택하세요.');

        return false;

      } else if (this.dateGubun == '') {
        alert('일자 기준을 선택하세요.');

        return false;

      } else {
        this.searchParams = new Object();
        this.searchParams.scrapGroupId = this.scrapGroup;
        this.searchParams.scrapId = this.scrapName;
        this.searchParams.status = this.scrapStatus;

        // 카드사/은행사
        for (var i = 0; i < this.cardBankCorpList.length; i++) {
          if (this.cardBankCorpList[i].key == this.scrapSiteKey && this.cardBankCorpList[i].key != '') {
            this.searchParams.scrapSiteName = this.cardBankCorpList[i].value;
          }
        }
        //  조회일자
        if (this.dateGubun == 'inqDate') {
          this.searchParams.inqStartDate = this.getFormatDate(this.fromDate, '-');
          this.searchParams.inqEndDate = this.getFormatDate(this.toDate, '-');

        } else if (this.dateGubun == 'sendDate') {
          this.searchParams.sendStartDate = this.getFormatDate(this.fromDate, '-');
          this.searchParams.sendEndDate = this.getFormatDate(this.toDate, '-');
        }
        //  세무대리인용 검색조건
        if (this.isCompanyOrAgentType == 'AGENT') {

          //  사업장번호
          this.searchParams.corpRegNo = this.enteredCorpRegNo;
          // 주민번호
          this.searchParams.birthday = this.enteredBirthday;
          //  상호
          this.searchParams.corpName = this.enteredCorpName;
          //  대표자
          this.searchParams.reprName = this.enteredReprName;
        }

        //  페이지 관련
        this.searchParams.pageIndex = this.currPage - 1;
        this.searchParams.pageLimit = this.pageLimit;
      }

      this.$cAxios.post("/api/scrap/history/getScrapHistoryListWithPaging", JSON.stringify(this.searchParams), this.jsonHeader)
          .then((response) => {

            // 테이블 초기화
            this.tableItems.items = [];
            if (response.data.content.length > 0) {
              this.totalListCnt = response.data.totalElements;

              for (var i = 0; i < response.data.content.length; i++) {
                //  사업자번호 format
                let corpRegNo = response.data.content[i].corpRegNo;
                if (corpRegNo != null) {
                  response.data.content[i].corpRegNo = this.formatCorpRegNo(corpRegNo);
                }

                //	조회기준에 신고구분이 들어갈 경우 조회일자 str 세팅 (합계표)
                if (response.data.content[i].inqType === "VAT_QUARTER") {
                	let vatQuarter = response.data.content[i].inqStartSubData;

                	if (vatQuarter === "1") {
						response.data.content[i].strVatQuarter = "1기 예정";
					} else if (vatQuarter === "2") {
						response.data.content[i].strVatQuarter = "1기 확정";
					} else if (vatQuarter === "3") {
						response.data.content[i].strVatQuarter = "1기 예정+확정";
					} else if (vatQuarter === "4") {
						response.data.content[i].strVatQuarter = "2기 예정";
					} else if (vatQuarter === "5") {
						response.data.content[i].strVatQuarter = "2기 확정";
					} else {
						response.data.content[i].strVatQuarter = "2기 예정+확정";
					}
				}

                this.tableItems.items.push(response.data.content[i]);
              }
            }
          })
          .catch((error) => {
            console.log(error.response.data.message);

          })
          .finally(() => {
            this.pageInit = false;
          })
    },
    //========== 스크랩핑 수동실행 1. ==========
    runScrappingManual(index) {

      if (confirm("수동실행 하시겠습니까?")) {
        this.param = {};
        var url = '';
        if (this.isCompanyOrAgentType == 'AGENT') {
          url = '/api/scrap/manual/scrapManualForAgent';
        } else {
          url = '/api/scrap/manual/scrapManual';
        }
        // 공통
        this.param.scrapGroupId = this.tableItems.items[index].scrapGroupId;
        this.param.scrapId = this.tableItems.items[index].scrapId;
        this.param.siteIdx = this.tableItems.items[index].siteIdx;

        let strScrapId = this.tableItems.items[index].scrapId;

        if (this.scrapGroup == 'HOMETAX') {
          if (this.isHometaxFromToYmdScrap(strScrapId)) {
            this.param.fromDate = this.getFormatDate(this.tableItems.items[index].inqStartDate, '.');
            this.param.toDate = this.getFormatDate(this.tableItems.items[index].inqEndDate, '.');

            this.popupFromDate = this.param.fromDate;
            this.popupToDate = this.param.toDate;

          } else if (this.isHometaxYearScrap(strScrapId)) {
            this.param.year = this.tableItems.items[index].inqStartYear;

            this.popupYear = this.param.year;

          } else if (this.isHometaxYearQuarterScrap(strScrapId)) {
			  let quarterData = this.tableItems.items[index].inqStartSubData;

			  //	request param & details popup 조회기준 세팅
			  this.param.year = this.tableItems.items[index].inqStartYear;
			  this.param.type = 'yearQuarter';
			  if (quarterData === "1") {
				  this.param.quarter = "Q1";
				  this.popupYearQuarter = '1분기'
			  } else if (quarterData === "2") {
				  this.param.quarter = "Q2";
				  this.popupYearQuarter = '2분기'
			  } else if (quarterData === "3") {
				  this.param.quarter = "Q3";
				  this.popupYearQuarter = '3분기'
			  } else {
				  this.param.quarter = "Q4";
				  this.popupYearQuarter = '4분기'
			  }
            this.popupYear = this.param.year;

          } else if (this.isHometaxVatQuarterScrap(strScrapId)) {
			  let quarterData = this.tableItems.items[index].inqStartSubData;

			  //	request param & details popup 조회기준 세팅
			  this.param.year = this.tableItems.items[index].inqStartYear;
			  this.param.type = 'vatQuarter';
			  if (quarterData === "1") {
				  this.param.quarter = "VAT_1";
				  this.popupVatQuarter = "1기 예정";
			  } else if (quarterData === "2") {
				  this.param.quarter = "VAT_2";
				  this.popupVatQuarter = "1기 확정";
			  } else if (quarterData === "3") {
				  this.param.quarter = "VAT_3";
				  this.popupVatQuarter = "1기 예정+확정";
			  } else if (quarterData === "4") {
				  this.param.quarter = "VAT_4";
				  this.popupVatQuarter = "2기 예정";
			  } else if (quarterData === "5") {
				  this.param.quarter = "VAT_5";
				  this.popupVatQuarter = "2기 확정";
			  } else {
				  this.param.quarter = "VAT_6";
				  this.popupVatQuarter = "2기 예정+확정";
			  }
			  this.popupYear = this.param.year;
		  }
          this.setScrapHometaxPeriodStandard(strScrapId);

        } else if (this.scrapGroup == 'NHIS') {
          if (this.isNhisFromToYmScrap(strScrapId)) {
            let start = this.getFormatDate(this.tableItems.items[index].inqStartDate, '.').toString();
            let end = this.getFormatDate(this.tableItems.items[index].inqEndDate, '.').toString();

            this.param.fromDate = start.substr(0, 6);
            this.param.toDate = end.substr(0, 6);

            this.popupFromDate = this.param.fromDate;
            this.popupToDate = this.param.toDate;
          }
          this.setScrapNhisPeriodStandard();

        } else if (this.scrapGroup == 'NPS') {
          if (this.isNpsFromToYmScrap(strScrapId)) {
            let start = this.getFormatDate(this.tableItems.items[index].inqStartDate, '.').toString();
            let end = this.getFormatDate(this.tableItems.items[index].inqEndDate, '.').toString();

            this.param.fromDate = start.substr(0, 6);
            this.param.toDate = end.substr(0, 6);

            this.popupFromDate = this.param.fromDate;
            this.popupToDate = this.param.toDate;
          }
          this.setScrapNpsPeriodStandard();

        } else if (this.scrapGroup == 'MEDI') {
          if (this.isMediYearScrap(strScrapId)) {

            this.param.year = this.tableItems.items[index].inqStartYear;
          }
          this.setScrapMediPeriodStandard();

        } else {
          this.param.fromDate = this.getFormatDate(this.tableItems.items[index].inqStartDate, '.');
          this.param.toDate = this.getFormatDate(this.tableItems.items[index].inqEndDate, '.');

          this.popupFromDate = this.param.fromDate;
          this.popupToDate = this.param.toDate;

          this.setScrapDefaultPeriodStandard();
        }

        // 홈택스:세무대리인일 때 특정 수임사
        if (this.isCompanyOrAgentType == 'AGENT') {
          // Service에서 단건,다건 모두 메서드 하나로 처리하려고 array에 담는다.
          var companies = new Array();
          companies[0] = this.tableItems.items[index].companyIdx;
          this.param.companies = companies;
        }
        // 카드,은행
        this.param.financeIdx = this.tableItems.items[index].financeIdx;

        this.$cAxios.post(url, JSON.stringify(this.param), this.jsonHeader)
            .then((response) => {
              // console.log('결과조회-수동실행 성공');
              this.popupHstGroupKey = response.data.historyGroupKey;
              this.detailsPopupTitle = response.data.scrapName;
              this.popupVisible = true;
            })
			.catch((error) => {
				alert(error.response.data.message);
				console.log(error.response.data.message);
			})
            .finally(() => {
				this.getScrapHistory(true);
            })
      } else {

        return false;
      }
    },
    //========== 세무대리인용 조회조건 기준 판단 메서드 1. ==========
    // scrapId에 따라 페이지 전체에 적용되는 기준을 바꾸는게 아니니까, scrapPeriodStandard 기준값 쓸 필요X
    // 바로 배열 State에서 찾아서 각 그리드 item에 적용하기
    isHometaxFromToYmdScrap(val) {
      return this.hometax_from_to_ymd.includes(val);
    },
    isHometaxYearScrap(val) {
      return this.hometax_year.includes(val);
    },
    isHometaxYearQuarterScrap(val) {
      return this.hometax_year_quarter.includes(val);
    },
    isHometaxUnnecessaryScrap(val) {
      return this.hometax_unnecessary.includes(val);
    },
	isHometaxVatQuarterScrap(val) {
	  return this.hometax_year_vat.includes(val);
	},
    isNhisFromToYmScrap(val) {
      return this.nhis_from_to_ym.includes(val);
    },
    isNpsFromToYmScrap(val) {
      return this.nps_from_to_ym.includes(val);
    },
    isMediYearScrap(val) {
      return this.medi_year.includes(val);
    },
    ...mapMutations({
      setScrapHometaxPeriodStandard: 'SET_SCRAP_HOMETAX_PERIOD_STANDARD',
      setScrapNhisPeriodStandard: 'SET_SCRAP_NHIS_PERIOD_STANDARD',
      setScrapNpsPeriodStandard: 'SET_SCRAP_NPS_PERIOD_STANDARD',
      setScrapMediPeriodStandard: 'SET_SCRAP_MEDI_PERIOD_STANDARD',
      setScrapDefaultPeriodStandard: 'SET_SCRAP_DEFAULT_PERIOD_STANDARD',
    }),
    //========== 기타 메서드 1. ==========
    // 일자 formatter
    getFormatDate(date, gubun) {
      var arr = date.split(gubun);
      var formatDate = '';
      for (var i = 0; i < arr.length; i++) {
        formatDate += arr[i];
      }
      return formatDate;
    },
    //  사업자번호 format
    formatCorpRegNo(str) {
      let originStr = str.toString();
      let formattedStr = '';

      if (originStr != null || originStr != '') {
        if (originStr.length == 10) {
          formattedStr = originStr.replace(/(\d{3})(\d{2})(\d{5})/, '$1-$2-$3');
        }
      }
      return formattedStr;
    },
  },
  computed: {
    ...mapState([
      'user',
      'auth',
      'hometax_from_to_ymd', 'hometax_year', 'hometax_year_quarter', 'hometax_unnecessary', 'hometax_year_vat',
      'nhis_from_to_ym',
      'nps_from_to_ym',
      'medi_year',
      'scrapPeriodStandard',
    ]),
    isCompanyOrAgentType() {
      return this.user.userInfo.COMPANY_OR_AGENT_FOR_VIEW;
    },
    // isUser() {
    //   return this.auth.userRole.ROLE.includes('USER')
    // },
    // isAgent() {
    //   return this.auth.userRole.ROLE.includes('AGENT')
    // },
  },
  watch: {
    scrapGroup() {
      this.getScrap(this.scrapGroup);

      if (this.scrapGroup == 'HOMETAX') {
        this.tableItems = this.hometaxTableItems
      } else if (this.scrapGroup == 'CARD') {
        this.tableItems = this.cardTableItems
      } else if (this.scrapGroup == 'BANK') {
        this.tableItems = this.bankTableItems
      } else if (this.scrapGroup == 'CREDIT') {
        this.tableItems = this.creditTableItems
      } else if (this.scrapGroup == 'NHIS') {
        this.tableItems = this.nhisTableItems
      } else if (this.scrapGroup == 'NPS') {
        this.tableItems = this.npsTableItems
      } else {
        this.tableItems = this.hiraTableItems
      }
    },
    currPage() {
      this.getScrapHistory(false);
    },
  },
  mounted() {
    // 스크랩핑 그룹 조회 메서드 호출
    this.getScrapGroup();
  }
}
</script>
<style lang="scss">
.table01 {
  //display: table;       //<table>태그가 아니라서 필요
  table-layout: fixed;    //테이블 내에서 td 넓이와 높이 고정
  width: 100%;            //width를 줘야 말줄임표 적용된다.
}
.table01 > td {
  display: table-cell;
}
.ellipsis {
  white-space: nowrap;      //td보다 내용이 길 경우 줄바꿈 방지
  overflow: hidden;
  text-overflow: ellipsis;  //overflow hidden과 세트
}
</style>